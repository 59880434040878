function checkLegacyPattern(
  name: string,
  selector: string,
  dataGetter?: (elem: Element) => unknown,
): void {
  const elements = document.querySelectorAll(selector);
  const { stack } = Error("");
  const pagecluster = window.o_util.misc.getPagecluster();

  const data = dataGetter ? Array.from(elements).map(dataGetter) : undefined;

  if (elements.length > 0) {
    window.AS.RUM.sendCustomRequest("assets-legacy-pattern", {
      name,
      pagecluster,
      count: elements.length,
      stack,
      data,
    });
  }
}

/*                                                            */
function logLegacyWarnings(): void {
  /*                         */

  checkLegacyPattern(
    "logo",
    [
      "#pl_logo_advertising",
      "#pl_logo_amex",
      "#pl_logo_giropay",
      "#pl_logo_giropay-grey",
      "#pl_logo_giropay_paydirekt",
      "#pl_logo_mastercard",
      "#pl_logo_visa",
      "#pl_logo_ratepay",
      "#pl_logo_otto-payments-lang-logo",
      "#pl_logo_reblog",
      "#pl_logo_psmh",
      "#pl_logo_updated",
      "#pl_logo_finanzplus",
      "#pl_logo_market",
      "#pl_logo_ready",
      "#pl_logo_retailmedia",
      "#pl_logo_cgi",
    ]
      .map((id) => `[src*="${id}"],[href*="${id}"]`)
      .join(),

    (el) => (el as SVGUseElement).href?.baseVal || (el as HTMLImageElement).src,
  );
}

export { logLegacyWarnings };

/*                                                  */
/*                                                             */

import { GlobalEvents, events } from "@otto-ec/global-resources/event-q-bus";
import type { CarouselPattern } from "./carousel/src";
import type { EventAcceptWrapper } from "./common/src/pattern/head";
import { logLegacyWarnings } from "./legacy_warnings";

/*     */
import type { Pali } from "./head";

export * from "./carousel/src/index";
export type {
  DropDownEvents,
  Pali,
  Presets,
  Sheet,
  SheetCloseTypes,
  SheetEvents,
  SnackbarEvents,
  SnackbarSettings,
  TextExpanderEvents,
  TooltipEvents,
  FocussedDialogEvents,
  FocussedDialogClassName,
  FocussedDialogClassNameHideInApp,
  FocussedDialogDataAttributeAddParams,
  FocussedDialogDataAttributePrevUrl,
  FocussedDialogDataAttributeCloseUrl,
} from "./head";

export interface GlobalPattern {
  carousel: EventAcceptWrapper<CarouselPattern>;
  /*                               */
}

declare global {
  interface OttoGlobal {
    /**
 *
 *
 *
 *
 *
 */
    pali: Pali;
  }

  interface Window {
    o_pattern: GlobalPattern;
  }
}

events<GlobalEvents>().once("assets.events.allJavascriptLoaded", logLegacyWarnings);

import { EmptyRecord } from "../definitions";
import { toggle } from "../global";
import { ComponentConstructor, getComponentConstructor } from "./construct";
import { registerComponent } from "./customElement";
import { ComponentDefinition } from "./definition";
import { AnyEmits } from "./events";
import { AnyProps } from "./props";

export { defineEmits, domOn } from "./events";
export type { AnyComponentEmits, ComponentEmits } from "./events";

export { defineProps } from "./props";
export type { PropType } from "./props";

/**
 *
 *
 *
 *
 */
export function defineComponent<
  Name extends string,
  Root extends HTMLElement,
  Props extends AnyProps,
  Data extends EmptyRecord,
  Emits extends AnyEmits
>(
  componentDef: ComponentDefinition<Name, Root, Props, Data, Emits>
): ComponentConstructor<Name, Root, Props, Emits> {
  /*                                  */
  if (toggle.get("PATTERN_ENABLE_CUSTOM_ELEMENT", false)) {
    registerComponent(componentDef);
  }
  return getComponentConstructor(componentDef);
}
